import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Carousel } from "antd"

import { Header } from "../../components/Header"
import { Layout } from "../../components/layout"
import { Footer } from "../../components/Footer"
import { Section } from "../../components/Section"
import { Container } from "../../components/Container"
import { Content } from "../../components/text/Content"
import { P } from "../../components/text/P"
import { FluidBackgroundImage } from "../../components/FluidBackgroundImage"

const StyledContent = styled(Content)`
  margin-left: auto;
  margin-right: auto;
`

const H1White = styled.h1`
  text-align: center;
  color: white;
`

const H2 = styled.h2`
  color: ${(props) => props.theme.ascent};
`

const CarouselSection = styled(Section)`
  margin-bottom: 0;
  background-color: gray;
`

const CarouselContainer = styled.div`
  padding: 0;
  @media all and (orientation: portrait) {
    padding: 0;
  }
  @media all and (orientation: landscape) {
    padding: 0 20vw;
  }
`

const Page = (props) => {
  const page =
    props.data.allDataJson.edges[0].node.pages.products_renewable_energy
  return (
    <Layout meta={page.meta} loadUikit={true}>
      <Header />
      <FluidBackgroundImage fluid={page.title_bg.childImageSharp.fluid}>
        <Section>
          <Container>
            <H1White>{page.title}</H1White>
          </Container>
        </Section>
      </FluidBackgroundImage>
      <Section>
        <Container>
          <StyledContent maxWidth={797}>
            <H2>{page.our_aim.title}</H2>
            <P data={page.our_aim.desc} />
            <H2>{page.our_advantage.title}</H2>
            <P data={page.our_advantage.desc} />
          </StyledContent>
        </Container>
      </Section>
      <CarouselSection>
        <CarouselContainer>
          <Carousel autoplay={true} adaptiveHeight={true}>
            {page.images.map((img, i) => (
              <Img key={i} fluid={img.childImageSharp.fluid} />
            ))}
          </Carousel>
        </CarouselContainer>
      </CarouselSection>
      <Footer />
    </Layout>
  )
}

export default Page

export const query = graphql`
  query {
    allDataJson {
      edges {
        node {
          pages {
            products_renewable_energy {
              meta {
                url
                title
                description
                imageRootUrl
                image
              }
              title
              title_bg {
                childImageSharp {
                  fluid(maxWidth: 1440, quality: 80) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              our_aim {
                title
                desc
              }
              our_advantage {
                title
                desc
              }
              images {
                childImageSharp {
                  fluid(maxWidth: 1120, quality: 80) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
